<template>
  <item
    :config="config"
    :open="open"
    :searchHighlights="searchHighlights"
    :listItem="listItem"
    :narrow="narrow"
    v-on="$listeners"
    class="regulation-item"
  >
    <div v-if="listItem" class="item__closed-stage">{{ listItem.stage }}</div>
  </item>
</template>

<script>
import Item from '../../components/Item/Item';
import { regulationItemPrepare } from './util/regulationItemPrepare';
import { mapActions, mapMutations } from 'vuex';

export default {
  components: { Item },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
    };
  },
  props: {
    open: Boolean,
    searchHighlights: {
      query: String,
      keywords: Array,
    },
    listItem: Object,
    narrow: Boolean,
  },
  data() {
    return {
      apiModules: {
        moduleId: 'pk_reg',
        modules: 'module_regulation_header',
        favoriteHated: 'favorite_and_hated_regulation_header',
        favoriteHatedResponse: 'favorite_and_hateful_regulations_list',
        bookmarks: 'bookmarks/fetchRegulations',
        list: 'regulations',
        listModules: 'custom_multi_query_reg',
      },
      config: {
        routeName: 'regulations-item',
        routeList: 'regulations',
        route404: 'regulations',
        prepareData: regulationItemPrepare,
        api: {
          body: 'regulation_body',
          highlights: 'regulations/load_highlights',
          monitored: 'regulations/load_provisions_with_favorite_flag',
          additional: 'regulation_provisions_and_versions',
          textVersions: {
            lastVersion: 'v1/regulation_last_version_button',
            version: 'v1/regulation_version',
          },
          descriptions: 'v1/regulation_other_descriptions',
        },
        bookmark: {
          type: 'regulations',
          fetch: 'fetchRegulations',
          toggle: 'toggleRegulations',
        },
        labels: {
          linksTitle: 'Projekt na stronie:',
          linksDoneTitle: 'Rozporządzenie na stronie:',
        },
      },
    };
  },
  created() {
    if (!this.listItem) {
      this.setApi(this.apiModules);
      this.getFavoriteHated();
      this.setModulesForCurrentList();
    }
  },
  methods: {
    ...mapMutations('list', ['setApi']),
    ...mapActions('list', ['getFavoriteHated', 'setModulesForCurrentList']),
  },
};
</script>

<style lang="scss">
@import './RegulationsItem';
</style>
